/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'ويزشات، دردشة بلا حدود',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  graphQL: 'https://api.pp.weezchat.fr/graphql',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  id: '1f55a8c3-7f3b-4582-936f-5c1876a1cf85',
  gtm: null,
  gtmCountry: 'GTM-N69PHL4',
  completeProfileInputs: ['phoneNumber'],
  dvp4m: true,
  gsv: null,

  appLink: null,
  didomi: null,
};
